import React, { useState, useEffect } from "react";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { accessToken, apiGetMethod, apiPost } from "../../api";
import {
  restaurant_categorylist,
  restaurant_addcategory,
  restaurant_editcategory,
  restaurant_deletecategory,
} from "../../api/constants";

export default function Inventory() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [editedCategoryName, setEditedCategoryName] = useState("");
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [searchText, setSearchText] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (category) => {
    setEditCategoryId(category._id);
    setEditedCategoryName(category.name);
    setShow2(true);
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const fetchCategoryList = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: restaurant_categorylist,
        token,
      });
      if(response?.data?.code == 403){
        console.log("REFRESH TOKEN")
        await accessToken()
      }

      if (response.data.status) {
        setCategories(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch category list");
    }
  };

  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      toast.error("Category name cannot be empty");
      return;
    }
    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_addcategory,
        token,
        data: { name: newCategoryName },
      });
       if(response?.data?.code == 403){
              console.log("REFRESH TOKEN")
              await accessToken()
            }

      if (response.data.status) {
        toast.success("Category added successfully");
        setNewCategoryName("");
        handleClose();
        fetchCategoryList();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to add category");
    }
  };

  const handleEditCategory = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_editcategory,
        token,
        data: { name: editedCategoryName, categoryId: editCategoryId },
      });
       if(response?.data?.code == 403){
              console.log("REFRESH TOKEN")
              await accessToken()
            }

      if (response.data.status) {
        toast.success("Category updated successfully");
        setEditedCategoryName(""); // Clear the input field
        handleClose2();
        fetchCategoryList(); // Refresh the category list
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to update category");
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_deletecategory,
        token,
        data: { categoryId: id },
      });
       if(response?.data?.code == 403){
              console.log("REFRESH TOKEN")
              await accessToken()
            }
            
      if (response.data.status) {
        toast.success("Category deleted successfully");
        fetchCategoryList(); // Refresh the category list
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete category");
    }
  };

  // Filter categories based on search text
  const filteredData = categories.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnview" to={`/MenuItems/${row._id}`}>
            <Icon icon="tabler:eye" />
          </Link>
          <Link className="btnedit" onClick={() => handleShow2(row)}>
            <Icon icon="tabler:pencil" />
          </Link>
          <Link
            className="btndelete"
            onClick={() => handleDeleteCategory(row._id)}
          >
            <Icon icon="tabler:trash" />
          </Link>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Inventory</h5>
          <Button className="btn btn-primary px-4" onClick={handleShow}>
            Add New Category
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>

      {/* Add Category Modal */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Add New Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4 form-group">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category name"
                value={newCategoryName}
                onChange={(e) => setNewCategoryName(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Button
            className="btn btn-primary px-4 w-100"
            onClick={handleAddCategory}
          >
            Add
          </Button>
        </Modal.Body>
      </Modal>

      {/* Edit Category Modal */}
      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-4 form-group">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category name"
                value={editedCategoryName}
                onChange={(e) => setEditedCategoryName(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Button
            className="btn btn-primary px-4 w-100"
            onClick={handleEditCategory}
          >
            Update
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
