import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import { apiPost } from "../../api";
import { restaurant_resetPassword } from "../../api/constants";
import { toast } from "react-toastify";

export default function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = location.state || {};

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    try {
      const response = await apiPost({
        url: restaurant_resetPassword,
        token: accessToken,
        data: {
          newPassword,
          confirmPassword,
        },
      });

      if(response?.data?.code == 403){
        console.log("REFRESH TOKEN")
        await accessToken()
      }
      

      if (response.data?.status) {
        toast.success(response?.data?.message);
        console.log(response.data);
        navigate("/");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      // Handle error response
      console.error("Error resetting password:", error);
      setError("Failed to reset password.");
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Reset Password</h2>
              <p>Please enter the details below to reset your password.</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100">
                Reset Password
              </Button>
              <p className="formfooter text-center mt-3">
                Back To <Link to="/">Login</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
