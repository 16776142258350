import React, { useState, useEffect } from "react";
import "./themes/main.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import OTP from "./components/pages/OTP";
import SignUp from "./components/pages/SignUp";
import Layout from "./components/layout/Layout";
import Dashboard from "./components/pages/Dashboard";
import OrdersManagement from "./components/pages/OrdersManagement";
import ViewOrders from "./components/pages/ViewOrders";
import ViewOrders2 from "./components/pages/ViewOrders2";
import OrdersDelivered from "./components/pages/OrdersDelivered";
import Inventory from "./components/pages/Inventory";
import MenuItems from "./components/pages/MenuItems";
import ProfileSettings from "./components/pages/ProfileSettings";
import EditProfile from "./components/pages/EditProfile";
import QandA from "./components/pages/QandA";
import Notifications from "./components/pages/Notifications";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { PrivateRoute } from "./PrivateRoute";

function App() {
  
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      const authStatus = localStorage.getItem("token") ? true : false;
      console.log(authStatus)
      setIsAuthenticated(authStatus);
      setLoading(false);
    }, 1000);

    console.log("isAuthenticated",isAuthenticated);

  }, []);

  return (
    <>
      {loading ? (
        <div className="loaderdiv">
          <Sentry color="#ddc45c" size={40} speed={1} animating={true} />
        </div>
      ) : (
        <Router basename={process.env.REACT_APP_BASENAME || "/"}>
          <Routes>
            <Route
              path="/"
              element={
                isAuthenticated ? <Navigate to="/Dashboard" /> : <Login />
              }
            />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/OTP" element={<OTP />} />
            <Route path="/ResetPassword" element={<ResetPassword />} />
            <Route path="/SignUp" element={<SignUp />} />
            <Route
              path="/Dashboard"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Dashboard"}>
                      <Dashboard />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/OrdersManagement"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Orders Management"}>
                      <OrdersManagement />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/ViewOrders"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"View Orders"}>
                      <ViewOrders />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/ViewOrders2"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"View Orders"}>
                      <ViewOrders2 />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/OrdersDelivered"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Orders Delivered"}>
                      <OrdersDelivered />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/Inventory"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Inventory"}>
                      <Inventory />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/QandA"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Q&A's"}>
                      <QandA />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/Notifications"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Notifications"}>
                      <Notifications />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/MenuItems/:id"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Menu Items"}>
                      <MenuItems />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/ProfileSettings"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Profile Settings"}>
                      <ProfileSettings />
                    </Layout>
                  }
                />
              }
            />
            <Route
              path="/EditProfile"
              element={
                <PrivateRoute
                  element={
                    <Layout title={"Edit Profile"}>
                      <EditProfile />
                    </Layout>
                  }
                />
              }
            />
          </Routes>
        </Router>
      )}
      <ToastContainer />
    </>
  );
}

export default App;
