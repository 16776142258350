import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import { accessToken, apiPost } from "../../api";
import { restaurant_forgotPassword } from "../../api/constants";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }
    try {
      const response = await apiPost({
        url: restaurant_forgotPassword,
        data: { email },
      });

      if(response?.data?.code == 403){
        console.log("REFRESH TOKEN")
        await accessToken()
      }

      if (response.data?.status) {
        const { OTP, restaurantId, hash } = response.data.data;
        navigate("/OTP", { state: { email, OTP, restaurantId, hash } });
      } else {
        toast.error(response.data?.message);
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Forgot Password</h2>
              <p>
                Please enter your email address to receive an OTP for password
                reset.
              </p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              <Button type="submit" className="btn btn-primary w-100 mt-3">
                Proceed
              </Button>
              <p className="formfooter text-center mt-3">
                Back To <Link to="/">Login</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="Logo" />
            </Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}
