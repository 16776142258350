import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { accessToken, apiGetMethod, apiPost } from "../../api"; // Import your API methods
import {
  restaurant_viewOrderDetail,
  restaurant_updateOrderStatus,
} from "../../api/constants"; // Import API constants
import { ENV } from "../../api/env";

export default function ViewOrders() {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderId, OrdersDelivered } = location.state || {};

  const [orderDetails, setOrderDetails] = useState(null);
  const [statusOptions] = useState([
    { value: 16, label: "Pending" },
    { value: 11, label: "In Progress" },
    { value: 13, label: "Completed" },
    { value: 17, label: "Cancelled" },
  ]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await apiGetMethod({
          url: `${restaurant_viewOrderDetail}?orderId=${orderId}`,
          token,
        });

        if(response?.data?.code == 403){
          console.log("REFRESH TOKEN")
          await accessToken()
        }

        if (response.status === 200) {
          setOrderDetails(response.data.data[0]); // Assuming data is an array and we need the first item
        } else {
          console.error("Failed to fetch order details");
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    if (orderId) {
      fetchOrderDetails();
    }
  }, [orderId]);

  const handleStatusChange = async (newStatusNumber) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiPost({
        url: restaurant_updateOrderStatus,
        data: {
          orderId: orderDetails.orderId,
          status: newStatusNumber,
        },
        token,
      });

       if(response?.data?.code == 403){
              console.log("REFRESH TOKEN")
              await accessToken()
            }
            

      if (response.data?.status) {
        setOrderDetails((prevDetails) => ({
          ...prevDetails,
          //   status: response.data.data.status,
          statusNumber: newStatusNumber,
        }));
      } else {
        console.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  if (!orderDetails) return <p>Loading...</p>;

  const isStatusPending = orderDetails.statusNumber === 16;
  const isStatusInProgress = orderDetails.statusNumber === 11;
  const isStatusCompleted = orderDetails.statusNumber === 13;
  const isStatusCancelled = orderDetails.statusNumber === 17;

  const isPendingDisabled = !isStatusPending || isStatusCompleted;
  const isInProgressDisabled = isStatusCompleted || isStatusCancelled;
  const isCompletedDisabled = isStatusCompleted || isStatusCancelled;
  const isCancelledDisabled = isStatusCompleted || isStatusCancelled;

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>View Orders</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 mt-2 mt-md-0"
            to={OrdersDelivered ? "/OrdersDelivered" : "/OrdersManagement"}
          >
            Back To Orders
          </Link>
        </Card.Header>
        <Card.Body className="bookingtable">
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <b>Order ID</b>
                </td>
                <td>#{orderDetails.orderId}</td>
              </tr>
              <tr>
                <td>
                  <b>Date</b>
                </td>
                <td>{new Date(orderDetails.createdAt).toLocaleDateString()}</td>
              </tr>
              <tr>
                <td>
                  <b>Deliver To</b>
                </td>
                <td>{`${orderDetails.destinationPoint.addressLine1}, ${orderDetails.destinationPoint.addressLine2}, ${orderDetails.destinationPoint.city}, ${orderDetails.destinationPoint.state}, ${orderDetails.destinationPoint.country}, ${orderDetails.destinationPoint.zipCode}`}</td>
              </tr>
              <tr>
                <td>
                  <b>Payment Type</b>
                </td>
                <td>{orderDetails.extraCharge.name}</td>
              </tr>
              {orderDetails?.status !== "Completed" && (
                <tr>
                  <td>
                    <b>Status</b>
                  </td>
                  <td>
                    <select
                      className="tabledropdown form-select"
                      value={orderDetails.statusNumber}
                      onChange={(e) =>
                        handleStatusChange(parseInt(e.target.value))
                      }
                    >
                      <option value={16} disabled={isPendingDisabled}>
                        Pending
                      </option>
                      <option value={11} disabled={isInProgressDisabled}>
                        In Progress
                      </option>
                      <option value={13} disabled={isCompletedDisabled}>
                        Completed
                      </option>
                      <option value={17} disabled={isCancelledDisabled}>
                        Cancelled
                      </option>
                    </select>
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <b>Customer Info</b>
                </td>
                <td>
                  <p>{orderDetails.username}</p>
                  <p>{orderDetails.userEmail}</p>
                  <p className="mb-0">{orderDetails.userMobileNumber}</p>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Order Details</b>
                </td>
                <td>
                  <Table responsive className="tableinner">
                    <thead>
                      <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderDetails.items.map((item, index) => (
                        <tr key={index}>
                          <td>
                            <img
                              src={ENV.S3_URL + item.productImage}
                              className="post-image img-fluid"
                              alt={item.productName}
                            />
                          </td>
                          <td>{item.productName}</td>
                          <td>{item.productDescription}</td>
                          <td>${item.price.price}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <Row className="justify-content-end vieworderdetail">
                    <Col md={6} lg={4} xl={3}>
                      <p>
                        Item Total <span>${orderDetails.totalItemPrice}</span>
                      </p>
                      <p>
                        Delivery Fee{" "}
                        <span>${orderDetails.extraCharge.amount}</span>
                      </p>
                      <p>
                        Coupon(CHEF50){" "}
                        <span>
                          - $
                          {orderDetails.extraCharge.amount -
                            (orderDetails.totalPrice -
                              orderDetails.totalItemPrice)}
                        </span>
                      </p>
                      <p>
                        <b>Total Amount</b> <b>${orderDetails.totalPrice}</b>
                      </p>
                    </Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
