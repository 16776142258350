import React, { useState, useEffect } from "react";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { accessToken, apiGetMethod, apiPost, apiPutMethod } from "../../api";
import {
  restaurant_addQAndA,
  restaurant_deleteQAndA,
  restaurant_editQAndA,
  restaurant_helplist,
} from "../../api/constants";

export default function QandA() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [qnaData, setQnaData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [editId, setEditId] = useState(null);

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchQnAList();
  }, []);

  const fetchQnAList = async () => {
    try {
      const response = await apiGetMethod({
        url: restaurant_helplist,
        token,
      });

      if(response?.data?.code == 403){
        console.log("REFRESH TOKEN")
        await accessToken()
      }

      if (response.data.status) {
        setQnaData(response.data.data);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to fetch Q&A list");
    }
  };

  const handleAddQnA = async () => {
    if (!question.trim() || !answer.trim()) {
      toast.error("Question and Answer fields cannot be empty");
      return;
    }

    try {
      const response = await apiPost({
        url: restaurant_addQAndA,
        token,
        data: { question, answer },
      });

       if(response?.data?.code == 403){
              console.log("REFRESH TOKEN")
              await accessToken()
            }

      if (response.data.status) {
        toast.success(response.data.message);
        setShow(false);
        fetchQnAList();
        setQuestion("");
        setAnswer("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to add Q&A");
    }
  };

  const handleEditQnA = async () => {
    if (!question.trim() || !answer.trim()) {
      toast.error("Question and Answer fields cannot be empty");
      return;
    }

    try {
      const response = await apiPost({
        url: restaurant_editQAndA,
        token,
        data: { helpId: editId, question, answer },
      });

       if(response?.data?.code == 403){
              console.log("REFRESH TOKEN")
              await accessToken()
            }

      if (response.data.status) {
        toast.success(response.data.message);
        setShow2(false);
        fetchQnAList();
        setQuestion("");
        setAnswer("");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to update Q&A");
    }
  };

  const handleDeleteQnA = async (id) => {
    try {
      const response = await apiPost({
        url: restaurant_deleteQAndA,
        token,
        data: { helpId: id },
      });

       if(response?.data?.code == 403){
              console.log("REFRESH TOKEN")
              await accessToken()
            }
            
      if (response.data.status) {
        toast.success(response.data.message);
        fetchQnAList();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Failed to delete Q&A");
    }
  };

  const filteredData = qnaData.filter(
    (item) =>
      item.question.toLowerCase().includes(searchText.toLowerCase()) ||
      item.answer.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = [
    {
      name: "Question",
      selector: (row) => row.question,
      sortable: true,
      maxWidth: "30%",
    },
    {
      name: "Answer",
      selector: (row) => row.answer,
      sortable: true,
    },
    {
      name: "Actions",
      maxWidth: "150px",
      cell: (row) => (
        <>
          <Link
            className="btnedit"
            onClick={() => {
              setEditId(row._id);
              setQuestion(row.question);
              setAnswer(row.answer);
              setShow2(true);
            }}
          >
            <Icon icon="tabler:pencil" />
          </Link>
          <Link className="btndelete" onClick={() => handleDeleteQnA(row._id)}>
            <Icon icon="tabler:trash" />
          </Link>
        </>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Question & Answers</h5>
          <Button
            className="btn btn-primary px-4"
            onClick={() => setShow(true)}
          >
            Add New
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>

      {/* Add Q&A Modal */}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Add Question & Answer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter answer"
                rows={5}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Button className="btn btn-primary px-4 w-100" onClick={handleAddQnA}>
            Add
          </Button>
        </Modal.Body>
      </Modal>

      {/* Edit Q&A Modal */}
      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        centered
        className="itemmodal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Question & Answer</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Answer</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter answer"
                rows={5}
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
              />
            </Form.Group>
          </Form>
          <Button
            className="btn btn-primary px-4 w-100"
            onClick={handleEditQnA}
          >
            Update
          </Button>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
