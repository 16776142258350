import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from "../assets/img/item4.jpg"; // Placeholder image
import { accessToken, apiGetMethod } from "../../api";
import { restaurant_profile } from "../../api/constants";
import { ENV } from "../../api/env";
import { Sentry } from "react-activity";

export default function ProfileSettings() {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await apiGetMethod({
          url: restaurant_profile,
          token,
        });
        if(response?.data?.code == 403){
          console.log("REFRESH TOKEN")
          await accessToken()
        }

        if (response.status === 200) {
          setProfile(response.data.data);
        } else {
          console.error("Failed to fetch profile");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, []);

  if (!profile)
    return (
      <div className="loaderdiv">
        <Sentry color="#ddc45c" size={40} speed={1} animating={true} />
      </div>
    );

  return (
    <React.Fragment>
      <Card className="view_profile">
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Profile Settings</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4"
            to="/EditProfile"
          >
            Edit Profile
          </Link>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={5} xl={4}>
              <div className="user_info text-center mb-3">
                <img
                  src={profile.image ? `${ENV.S3_URL}${profile.image}` : Avatar}
                  className="rounded-circle img-fluid profilepic"
                  alt="Profile"
                />
                <h4>{profile.companyName}</h4>
                <p>{profile.email}</p>
              </div>
            </Col>
            <Col md={7} xl={8}>
              <Row>
                <Col lg={6} className="mb-3">
                  <div className="form-group">
                    <Form.Label className="w-100">Company Name</Form.Label>
                    <span>{profile.companyName}</span>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="form-group">
                    <Form.Label className="w-100">Title</Form.Label>
                    <span>{profile.title}</span>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="form-group">
                    <Form.Label className="w-100">Email Address</Form.Label>
                    <span>{profile.email}</span>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="form-group">
                    <Form.Label className="w-100">Location</Form.Label>
                    <span>{profile.location}</span>
                  </div>
                </Col>

                <Col lg={6} className="mb-3">
                  <div className="form-group">
                    <Form.Label className="w-100">Bio</Form.Label>
                    <span className="w-100">{profile.description}</span>
                  </div>
                </Col>
                <Col lg={6} className="mb-3">
                  <div className="form-group">
                    <Form.Label className="w-100">Opening Hours</Form.Label>
                    <span className="w-100">{`${profile.openTime} - ${profile.closeTime}`}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
