import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { accessToken, apiGetMethod } from "../../api";
import { restaurant_notification } from "../../api/constants";

export default function Notifications() {
  const [notifications, setNotifications] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiGetMethod({
        url: restaurant_notification,
        token,
      });


      if(response?.data?.code == 403){
        console.log("REFRESH TOKEN")
        await accessToken()
      }

      // Ensure that notificationList is an array
      const notificationList = response.data?.data || [];
      setNotifications(notificationList);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="py-4">
          <h5>Notifications</h5>
        </Card.Header>
        <Card.Body>
          {notifications.length > 0 ? (
            notifications.map((item, index) => (
              <div key={item._id} className="notificationlist mb-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h2>{item.title}</h2>{" "}
                  <span className="notifytime">
                    <Icon icon="tabler:clock" />
                    {new Date(item.createdAt).toLocaleTimeString()}
                  </span>
                </div>
                <p>{item.content}</p>
                {/* {index === 0 && (
                  <div className="mt-3">
                    <Link className="btnview2">Accept</Link>
                    <Link className="btnedit2">Reject</Link>
                  </div>
                )} */}
              </div>
            ))
          ) : (
            <p>No notifications available.</p>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
