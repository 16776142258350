import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Modal, Col, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import Autocomplete from "react-google-autocomplete";
import { ENV } from "../../api/env";
import payment1 from "../assets/img/payment1.png";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import { accessToken, apiPost } from "../../api";
import { signupRestaurant } from "../../api/constants";
import { toast } from "react-toastify";
import "../../themes/main.scss";

export default function SignUp() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [searchPlace, setPlace] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    description: "",
    openTime: "",
    closeTime: "",
    location: {},
    email: "",
    password: "",
  });
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePlaceSelect = (place) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    setPlace(place.formatted_address || "");
    setFormData((prevData) => ({
      ...prevData,
      location: {
        name: place.formatted_address,
        latitude: lat,
        longitude: lng,
      },
    }));
  };

  const handleData = (e) =>
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    if (file) {
      setFilePreview(URL.createObjectURL(file));
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFilePreview("");
  };

  const validateForm = () => {
    const requiredFields = [
      "title",
      "name",
      "description",
      "openTime",
      "closeTime",
      "location",
      "email",
      "password",
    ];

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    for (let field of requiredFields) {
      if (
        !formData[field] ||
        (field === "location" && !formData.location.name)
      ) {
        toast.error(`Please fill in all required fields: ${field}`);
        return false;
      }
    }

    if (!emailRegex.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    if (!file) {
      toast.error("Please upload a photo.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const form = new FormData();
    form.append("title", formData.title);
    form.append("name", formData.name);
    form.append("description", formData.description);
    form.append("openTime", formData.openTime);
    form.append("closeTime", formData.closeTime);
    form.append("location", JSON.stringify(formData.location));
    if (file) form.append("image", file);
    form.append("email", formData.email);
    form.append("password", formData.password);

    try {
      const response = await apiPost({
        url: signupRestaurant(),
        data: form,
      });
      if(response?.data?.code == 403){
        console.log("REFRESH TOKEN")
        await accessToken()
      }

      if (response.data.status) {
        toast.success(response.data.message);
        localStorage.setItem("token", response.data?.data?.accessToken);
        localStorage.setItem("refreshToken", response.data?.data?.refreshToken);
        localStorage.setItem("restaurantId", response.data?.data?.restaurantId);
        localStorage.setItem("image", response.data?.data?.image);

        handleShow();
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("An error occurred:", err);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Create an Account</h2>
              <p>Please fill in the details below to create an account.</p>
            </div>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Restaurant Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      value={formData.name}
                      onChange={handleData}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      value={formData.title}
                      onChange={handleData}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleData}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={6}>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Location</Form.Label>
                    <div className="autocomplete-wrapper">
                      <Autocomplete
                        apiKey={ENV.GOOGLE_MAP_APIKEY}
                        onPlaceSelected={(place) => handlePlaceSelect(place)}
                        value={searchPlace}
                        onChange={(e) => setPlace(e.target.value)}
                        placeholder="Enter the location"
                        required
                        className="form-control"
                        types={["address"]}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Opening Hours</Form.Label>
                    <Row>
                      <Col lg={6}>
                        <Form.Control
                          type="time"
                          name="openTime"
                          value={formData.openTime}
                          onChange={handleData}
                          required
                        />
                      </Col>
                      <Col lg={6}>
                        <Form.Control
                          type="time"
                          name="closeTime"
                          value={formData.closeTime}
                          onChange={handleData}
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Bio</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="description"
                      value={formData.description}
                      onChange={handleData}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-3 form-group">
                    <Form.Label>Upload Photo</Form.Label>
                    <div className="image-upload-container">
                      {!filePreview && (
                        <>
                          <label htmlFor="uploadphoto2" className="uploadphoto">
                            <Icon icon="solar:add-square-linear" />
                          </label>
                          <input
                            type="file"
                            id="uploadphoto2"
                            className="d-none"
                            onChange={handleFileChange}
                            required
                          />
                        </>
                      )}
                      {filePreview && (
                        <div className="image-preview">
                          <img
                            src={filePreview}
                            alt="Preview"
                            className="img-thumbnail"
                          />
                          <div
                            className="close-icon"
                            onClick={handleFileRemove}
                          >
                            <Icon icon="mdi:close" />
                          </div>
                        </div>
                      )}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg={12}>
                  <Form.Group className="mb-4 form-group">
                    <Form.Label>Password</Form.Label>
                    <div className="password-input-container">
                      <Form.Control
                        type={passwordVisible ? "text" : "password"}
                        placeholder="Enter password"
                        name="password"
                        value={formData.password}
                        onChange={handleData}
                        required
                        className="password-input"
                      />
                      <Icon
                        icon={passwordVisible ? "mdi:eye-off" : "mdi:eye"}
                        className="password-toggle-icon"
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      />
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center mt-4">
                <Button
                  type="submit"
                  className="comnbtnclass w-100"
                  disabled={!formData.password}
                >
                  Sign Up
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col lg={6} className="d-lg-block d-none p-0">
          <div className="loginsideimg">
            <div className="d-flex align-items-center justify-content-center">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="pay_icon">
              <img src={payment1} alt="" />
            </div>
          </div>
        </Col>
      </Row>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="comnmodal"
        size="sm"
      >
        <Modal.Header closeButton className="border-0 p-0">
          <div className="text-center w-100 pt-3 mx-auto">
            <Icon
              icon="ph:check-circle-bold"
              className="comnmodalicon text-center"
            />
          </div>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="modal_text">
            <p>Your Account has been created successfully.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-0 border-0">
          <Button
            className="comnbtnclass w-100 m-0"
            onClick={() => navigate("/dashboard")}
          >
            Go to Dashboard
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
