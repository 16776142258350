import axios from 'axios';
import { ENV } from './env';

const axiosInstance = axios.create({
  baseURL: ENV.BASE_URL,
});

const apiGetMethod = ({url, token}) => {
  let headers = {};
  if (token) headers.authorization = 'Bearer' + ' ' + token;

  return axiosInstance({
    method: 'get',
    url,
    headers,
  });
};

const apiPost = ({url, token, data = {}}) => {
  let headers = {};
  if (token) headers.authorization = 'Bearer' + ' ' + token;
  return axiosInstance({
    method: 'post',
    url,
    data,
    headers,
  });
};

const apiPutMethod = ({url, token, data = {}}) => {
  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };
  if (token) headers.authorization = 'Bearer' + ' ' + token;
  return axiosInstance({
    method: 'put',
    url,
    data,
    headers,
  });
};

const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");

  console.log("refreshToken")

  if (!refreshToken) {
    throw new Error("No refresh token available");
  }

  try {
    const response = await axios.post(
      `${ENV.BASE_URL}refreshToken`,
      {
        refreshToken:refreshToken
      },
      // {
      //   headers: {
      //     "authorization": refreshToken,
      //   },
      // }
    );

    console.log("Response-----RT",response)

    if(response?.data?.status == true){

      console.log("response=====TRUE", response?.data?.data)
    const token = response?.data?.data?.userToken;
    const refreshToken= response?.data?.data?.refreshToken;
    console.log("TOKEN",token)
    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken",refreshToken)
    console.log(response.data, "sasas");
    return token;
    }else{
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("token");
      localStorage.removeItem("restaurantId");
      window.location.href = "/";
    }
  } catch (error) {
    console.log("ERROR---->RefreshToken",error)
    if (error?.response?.data?.status_code === 401) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("token");
      localStorage.removeItem("restaurantId");
      window.location.href = "/";
    }
    throw new Error("Failed to refresh token");
  }
};

//Get Token 
const accessToken= async()=>{
  console.log("Hii","ACCESSS KEY")
  try {
    let headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };

    const newToken = await refreshToken();

    console.log("newToken",newToken)
    // Retry the original request with the new token
    let result = headers.authorization = 'Bearer' + ' ' + newToken;
    return axios(result);
  } catch (refreshError) {
    // Handle token refresh failure
    console.error("Token refresh failed:", refreshError);
    // Redirect to login or handle as needed
    return Promise.reject(refreshError);
  }
}

export default axiosInstance;
export {apiGetMethod, apiPutMethod, apiPost,accessToken};
